@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.content {
  padding: 0 10px 0;
  min-height: calc(100vh - 200px);
}

.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
  z-index: 2;
}

.add-line {
  color: #409eff;
  text-align: center;
  margin-top: 20px;

  span {
    cursor: pointer;
  }
}

.email-err {
  color: #ff4d4f;
  font-size: 12px;
  text-align: left;
  margin-top: -5px;
}

::v-deep {
  .email-wrap {
    position: relative;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    .el-form-item {
      padding: 0 10px 0 0 !important;
      width: 100%;
    }

    > i {
      font-size: 24px;
      cursor: pointer;
      margin-top: 5px;
      color: #409eff;
    }
  }

  .el-rate span {
    display: inline !important;
    padding: 0 !important;
  }
}
